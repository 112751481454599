<template>
	<div class="hub-page-editor">
		<v-form ref="pageForm" v-model="validPageForm" :disabled="pageSaving">
			<div class="page-details page-details-row">
				<div class="page-details-col col-title">
					<v-text-field
						v-model="mPage.title"
						:rules="[form_rules.required]"
						required
						label="Hub Page Title*"
						clearable
						class="mt-0 pt-0"
						@change="mPage.title = $event?.trim()"
					/>
					<v-text-field
						ref="pageFormSlug"
						v-model="mPage.slug"
						:rules="[form_rules.required, uniqueSlugRule()]"
						:readonly="isEditMode"
						required
						label="Slug*"
						class="mt-2"
						@change="mPage.slug = $event?.trim()"
					/>
					<div class="fields-row mt-2">
						<div class="fields-col">
							<DivisionSelector v-model="mPage.division" />
						</div>
						<div class="fields-col">
							<v-select
								v-model="mPage.status"
								:items="statusOptions"
								label="Status"
							/>
						</div>
					</div>
				</div>
				<div class="page-details-col col-description">
					<div class="field-bordered-wrapper">
						<v-textarea
							v-model="mPage.description"
							:rules="[form_rules.required, counterRule(pageDescriptionCounter)]"
							label="Page Description*"
							placeholder=" "
							:counter="pageDescriptionCounter"
							:rows="3"
							class="description-field"
							@change="mPage.description = $event?.trim()"
						/>
					</div>
				</div>
			</div>
		</v-form>

		<div class="page-items page-items-row">
			<div class="page-items-col col-categories">
				<div class="hub-page-title">
					Categories
					<v-tooltip bottom>
						<template #activator="{ on, attrs }">
							<v-icon class="ml-2" v-bind="attrs" v-on="on">icon-info-circle-q</v-icon>
						</template>
						<div class="hub-pages-tooltip-info" v-html="categoriesInfo" />
					</v-tooltip>
				</div>

				<draggable
					v-model="mPage.categories"
					draggable=".draggable-item"
					handle=".handle-item"
				>
					<v-list-item
						v-for="(category) in mPage.categories" :key="getIdentifier(category)"
						class="draggable-item list-item"
						:class="checkCurrentCategory(category) && 'list-item--active'"
						@click="setCurrentCategory(category)"
					>
						<v-icon v-if="hasItemsValidationErrors && itemsValidation[getIdentifier(category)]" class="validation-mark">fas fa-exclamation-triangle</v-icon>
						<v-list-item-icon class="handle-item">
							<v-icon color="grey lighten-2">fas fa-arrows-alt</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="list-item__title">
							{{ category.title || NEW_CATEGORY_TITLE }}
						</v-list-item-title>
						<v-list-item-action class="list-item__action">
							<v-btn icon @click.stop="setCurrentCategory(category)">
								<v-icon>fas fa-edit</v-icon>
							</v-btn>
							<v-btn icon @click.stop="displayDeleteItemConfirmation(category, 'category')">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
					<template #footer>
						<v-list-item class="mb-8" @click="addCategory">
							<v-list-item-icon>
								<v-icon color="grey">fas fa-plus</v-icon>
							</v-list-item-icon>
							<v-list-item-title class="hub-pages__empty-item">Add New</v-list-item-title>
						</v-list-item>
					</template>
				</draggable>
			</div>

			<div class="page-items-col col-topics">
				<div class="hub-page-title">
					Topics
					<v-tooltip bottom>
						<template #activator="{ on, attrs }">
							<v-icon class="ml-2" v-bind="attrs" v-on="on">icon-info-circle-q</v-icon>
						</template>
						<div class="hub-pages-tooltip-info" v-html="topicsInfo" />
					</v-tooltip>
				</div>

				<draggable
					v-if="currentCategory"
					v-model="currentCategory.topics"
					draggable=".draggable-item"
					handle=".handle-item"
				>
					<v-list-item
						v-for="(topic) in currentCategory.topics" :key="getIdentifier(topic)"
						class="draggable-item list-item"
						:class="checkCurrentTopic(topic) && 'list-item--active'"
						@click="setCurrentTopic(topic)"
					>
						<v-icon v-if="hasItemsValidationErrors && itemsValidation[getIdentifier(topic)]" class="validation-mark">fas fa-exclamation-triangle</v-icon>
						<v-list-item-icon class="handle-item">
							<v-icon color="grey lighten-2">fas fa-arrows-alt</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="list-item__title">
							{{ topic.title || NEW_TOPIC_TITLE }}
						</v-list-item-title>
						<v-list-item-action class="list-item__action">
							<v-btn icon @click.stop="setCurrentTopic(topic)">
								<v-icon>fas fa-edit</v-icon>
							</v-btn>
							<v-btn icon @click.stop="displayDeleteItemConfirmation(topic, 'topic')">
								<v-icon>fas fa-times</v-icon>
							</v-btn>
						</v-list-item-action>
					</v-list-item>
					<template #footer>
						<v-list-item class="mb-8" @click="addTopic">
							<v-list-item-icon>
								<v-icon color="grey">fas fa-plus</v-icon>
							</v-list-item-icon>
							<v-list-item-title class="hub-pages__empty-item">Add New</v-list-item-title>
						</v-list-item>
					</template>
				</draggable>

				<div v-else class="hub-page-tip">Please, select the Category</div>
			</div>

			<div class="page-items-col col-editor">
				<div class="hub-page-title editor-title">{{ editorTitle }}</div>
				<div v-if="!hasItemToEdit" class="hub-page-tip">Please, select a Category or Topic item to edit</div>
				<v-form v-else ref="itemForm" v-model="validItemForm" :disabled="pageSaving">
					<div v-if="currentTopic" :key="getIdentifier(currentTopic)" class="editor-wrapper">
						<v-text-field
							v-model="currentTopic.title"
							:rules="[form_rules.required, topicNameRule]"
							required
							label="Topic Title*"
							clearable
							@change="currentTopic.title = $event?.trim()"
						/>
						<div class="field-bordered-wrapper mt-2">
							<v-textarea
								v-model="currentTopic.description"
								:rules="[form_rules.required, counterRule(topicDescriptionCounter)]"
								label="Description*"
								placeholder=" "
								:counter="topicDescriptionCounter"
								:rows="3"
								class="description-field"
								@change="currentTopic.description = $event?.trim()"
							/>
						</div>
						<div class="field-bordered-wrapper mt-3">
							<v-text-field
								v-model="currentTopic.buttonTitle"
								:rules="[form_rules.required, counterRule(buttonTitleCounter)]"
								required
								label="Button Title*"
								:counter="buttonTitleCounter"
								class="mt-0"
								@change="currentTopic.buttonTitle = $event?.trim()"
							/>
						</div>
						<v-text-field
							v-model="currentTopic.buttonLink"
							:rules="[form_rules.required]"
							required
							label="Button Link*"
							append-icon="fas fa-link"
							class="mt-4"
							@change="currentTopic.buttonLink = $event?.trim()"
						/>
						<RoleSelector
							v-model="currentTopic.roles"
							class="mt-2"
						/>
						<PermissionSelector
							v-model="currentTopic.permissions"
							class="mt-2"
						/>
						<div class="status-fields-row">
							<div class="status-col">
								<v-select
									v-model="currentTopic.status"
									:items="statusOptions"
									label="Status"
									@input="onStatusChange($event, currentTopic)"
								/>
							</div>
							<div class="dates-row">
								<div class="dates-col">
									<QDateInput
										v-model="currentTopic.publishDate"
										:rules="currentTopic.status === STATUS.publish ? [form_rules.required] : undefined"
										label="Publish Date"
									/>
								</div>
								<div class="dates-col">
									<QDateInput
										v-model="currentTopic.endDate"
										label="End Date"
									/>
								</div>
							</div>
						</div>
					</div>

					<div v-else-if="currentCategory" :key="getIdentifier(currentCategory)" class="editor-wrapper">
						<v-text-field
							v-model="currentCategory.title"
							:rules="[form_rules.required, categoryNameRule]"
							required
							label="Category Title*"
							clearable
							@change="currentCategory.title = $event?.trim()"
						/>
						<v-select
							v-model="currentCategory.status"
							:items="statusOptions"
							label="Status"
							class="mt-2"
							@input="onStatusChange($event, currentCategory)"
						/>
						<div class="fields-row">
							<div class="fields-col">
								<QDateInput
									v-model="currentCategory.publishDate"
									:rules="currentCategory.status === STATUS.publish ? [form_rules.required] : undefined"
									label="Publish Date"
								/>
							</div>
							<div class="fields-col">
								<QDateInput
									v-model="currentCategory.endDate"
									label="End Date"
								/>
							</div>
						</div>
					</div>
				</v-form>
			</div>
		</div>

		<v-alert v-if="warningMessage" type="error" class="hub-page-editor__warning-message">{{ warningMessage }}</v-alert>

		<div class="full-width d-flex justify-end pt-4 pb-2">
			<v-btn v-bind="previewButtonProps" @click="preview">
				<v-icon v-if="isMobileView">fas fa-eye</v-icon>
				<template v-else>Preview</template>
			</v-btn>
			<v-btn elevation="0" class="mr-2" @click="cancel">Cancel</v-btn>
			<v-btn elevation="0" color="primary" width="6.5rem" :disabled="pageSaving" @click="save">
				<v-progress-circular v-if="pageSaving" class="mr-1" left size="16" width="2" indeterminate />
				{{ saveButtonLabel }}
			</v-btn>
		</div>

		<v-dialog
			v-if="doShowConfirmDeleteDialog"
			v-model="doShowConfirmDeleteDialog"
			width="95%"
			max-width="360px"
		>
            <v-card>
                <v-card-title class="headline error" color="error">
                    <h5 class="q_white-text">{{ confirmDeleteDialogTitle }}</h5>
                </v-card-title>
                <v-divider />
                <v-card-actions class="py-4 px-4">
                    <v-btn color="error" outlined @click="deleteItem">Delete</v-btn>
                    <v-spacer />
                    <v-btn color="error" depressed @click="doShowConfirmDeleteDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<v-dialog
			v-if="doShowSlugTakenErrorDialog"
			v-model="doShowSlugTakenErrorDialog"
			width="95%"
			max-width="440px"
		>
            <v-card>
                <v-card-title class="headline error py-4 px-4" color="error">
                    <h5 class="q_white-text mb-0">{{ slugTakenError }}</h5>
                </v-card-title>
				<v-card-text class="py-4 px-4">
                    <p class="body mb-0">Please change the page <strong>Slug</strong> and try saving again.</p>
                </v-card-text>
                <v-card-actions class="py-4 px-4">
                    <v-spacer />
                    <v-btn depressed @click="doShowSlugTakenErrorDialog = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<HubPagePreviewDialog
			v-if="doShowViewerDialog"
			v-model="doShowViewerDialog"
			:page="mPage"
			:mode="PAGE_VIEW_MODE.view"
		/>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment'
import slugify from 'slugify'

import QDateInput from '@/components/utils/QDateInput.vue'
import PermissionSelector from './PermissionSelector.vue'
import RoleSelector from './RoleSelector.vue'
import DivisionSelector from './DivisionSelector.vue'
import HubPagesDialog from './HubPagesDialog.vue'
import HubPagePreviewDialog from './HubPagePreviewDialog.vue'
import { createHubPage, updateHubPage } from '../api/index'
import {
	getNewItem, copyPageItem, createNewFromPageItem, dateRule, getIdentifier,
	EDITOR_MODE, NEW_ID, STATUS, NEW_CATEGORY_TITLE, NEW_TOPIC_TITLE, PAGE_VIEW_MODE,
} from '../config/index'

const defaultPage = {
	id: NEW_ID,
	title: '',
	slug: '',
	division: 'All',
	status: STATUS.draft,
	description: '',
	categories: [],
	lastChangeBy: '',
	dateCreated: null,
	dateUpdated: null,
}

const defaultCategory = {
	id: NEW_ID,
	title: '',
	status: STATUS.draft,
	publishDate: null,
	endDate: null,
	topics: [],
}

const defaultTopic = {
	id: NEW_ID,
	title: '',
	description: '',
	buttonTitle: '',
	buttonLink: '',
	roles: null,
	permissions: null,
	status: STATUS.draft,
	publishDate: null,
	endDate: null,
}

export default {
	components: {
		draggable,
		QDateInput,
		PermissionSelector,
		RoleSelector,
		DivisionSelector,
		HubPagesDialog,
		HubPagePreviewDialog,
    },
	props: {
		page: { type: Object, default: null },
		mode: { type: String, default: EDITOR_MODE.new },
		loading: { type: Boolean, default: false },
	},
	data () {
		return {
			PAGE_VIEW_MODE,
			STATUS,
			NEW_CATEGORY_TITLE,
			NEW_TOPIC_TITLE,
			statusOptions: [STATUS.publish, STATUS.draft, STATUS.trash],
			pageDescriptionCounter: 155,
			topicDescriptionCounter: 292,
			buttonTitleCounter: 20,

			mPage: {},
			pageSaving: false,
			pageError: false,

			currentCategory: null,
			currentTopic: null,

			displayItemsValidation: false,
			validPageForm: false,
			validItemForm: false,
			warningMessage: '',
			timeout: null,

			doShowConfirmDeleteDialog: false,
			confirmDeleteItem: null,
			confirmDeleteItemType: null,

			doShowViewerDialog: false,

			categoriesInfo: 'Categories Info<br>Lorem ipsum ...',
			topicsInfo: 'Topics Info<br>Lorem ipsum ...',

			existingSlugs: [],
			slugTakenError: 'The slug has already been taken',
			doShowSlugTakenErrorDialog: false,
		}
	},
	created () {
		this.getModelPage()
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		hasItemToEdit () {
			return !!(this.currentTopic || this.currentCategory)
		},
		editorTitle () {
			if (this.currentTopic) { return 'Edit Topic' }
			if (this.currentCategory) { return 'Edit Category' }
			return 'Edit Item'
		},
		itemsValidation () {
			const invalidItemIds = {}
			this.mPage.categories?.forEach((category) => {
				let hasInvalidTopics = false
				category.topics?.forEach((topic) => {
					if (!this.validateTopicFields(category, topic)) {
						invalidItemIds[this.getIdentifier(topic)] = 'topic'
						hasInvalidTopics = true
					}
				})
				if (hasInvalidTopics || !this.validateCategoryFields(category)) {
					invalidItemIds[this.getIdentifier(category)] = 'category'
				}
			})
			return invalidItemIds
		},
		hasItemsValidationErrors () {
			if (!this.displayItemsValidation) { return false }
			return !!Object.keys(this.itemsValidation).length
		},
		saveButtonLabel () {
			if (this.mode === EDITOR_MODE.edit) { return 'Save' }
			return 'Create'
		},
		previewButtonProps () {
			if (this.isMobileView) {
				return {
					icon: true,
					outlined: true,
					class: 'mr-auto',
				}
			}
			return {
				text: true,
				class: 'preview-btn',
			}
		},
		confirmDeleteDialogTitle () {
			if (!this.confirmDeleteItem) { return '' }
			const itemTitle = this.confirmDeleteItem.title ||
				(this.confirmDeleteItemType === 'category' ? this.NEW_CATEGORY_TITLE : this.NEW_TOPIC_TITLE)
			return `Are you sure you want to delete "${itemTitle}" ${this.confirmDeleteItemType}?`
		},
		isEditMode () {
			return this.mode === EDITOR_MODE.edit
		},
	},
	watch: {
		page () {
			this.getModelPage()
		},
		mode () {
			this.getModelPage()
		},
		'mPage.title' (v) {
			if (this.isEditMode) { return }
			this.mPage.slug = v ? slugify(v, { lower: true, strict: true }) : ''
		},
		doShowConfirmDeleteDialog (v) {
			if (!v) {
				this.confirmDeleteItem = null
				this.confirmDeleteItemType = null
			}
		},
		doShowSlugTakenErrorDialog (v) {
			if (!v) {
				this.$refs['pageFormSlug'].validate()
			}
		},
	},
	methods: {
		getIdentifier,
		getModelPage () {
			this.resetItemsValidationDisplay()
			this.resetPageFormValidationDisplay()
			if (this.mode === EDITOR_MODE.new) {
				this.mPage = getNewItem(defaultPage)
			} else if (this.mode === EDITOR_MODE.copy) {
				this.mPage = createNewFromPageItem(this.page)
			} else {
				this.mPage = copyPageItem(this.page)
			}
			this.currentCategory = null
			this.currentTopic = null
		},
		checkCurrentCategory (category) {
			return this.getIdentifier(this.currentCategory) === this.getIdentifier(category)
		},
		checkCurrentTopic (topic) {
			return this.getIdentifier(this.currentTopic) === this.getIdentifier(topic)
		},
		setCurrentCategory (category) {
			if (!category) { return }
			this.currentCategory = category
			this.currentTopic = null
			this.validateCurrentItemForm()
		},
		setCurrentTopic (topic) {
			if (!topic) { return }
			this.currentTopic = topic
			this.validateCurrentItemForm()
		},
		addCategory () {
			const newCategory = getNewItem(defaultCategory)
			this.mPage.categories = [...this.mPage.categories, newCategory]
			this.currentTopic = null
			this.currentCategory = newCategory
			this.resetItemsValidationDisplay()
		},
		addTopic () {
			const newTopic = getNewItem(defaultTopic)
			this.currentCategory.topics = [...this.currentCategory.topics, newTopic]
			this.currentTopic = newTopic
			this.resetItemsValidationDisplay()
		},
		displayDeleteItemConfirmation (item, itemType) {
			this.doShowConfirmDeleteDialog = true
			this.confirmDeleteItem = item
			this.confirmDeleteItemType = itemType
		},
		deleteItem () {
			if (this.confirmDeleteItemType === 'category') {
				this.deleteCategoryItem(this.confirmDeleteItem)
			} else {
				this.deleteTopicItem(this.confirmDeleteItem)
			}
			this.doShowConfirmDeleteDialog = false
		},
		deleteCategoryItem (category) {
			if (this.checkCurrentCategory(category)) {
				this.currentCategory = null
				this.currentTopic = null
			}
			const id = this.getIdentifier(category)
			const index = this.mPage.categories.findIndex((item) => this.getIdentifier(item) === id)
			this.mPage.categories = [
				...this.mPage.categories.slice(0, index),
				...this.mPage.categories.slice(index + 1),
			]
		},
		deleteTopicItem (topic) {
			if (this.checkCurrentTopic(topic)) {
				this.currentTopic = null
			}
			const id = this.getIdentifier(topic)
			const index = this.currentCategory.topics.findIndex((item) => this.getIdentifier(item) === id)
			this.currentCategory.topics = [
				...this.currentCategory.topics.slice(0, index),
				...this.currentCategory.topics.slice(index + 1),
			]
		},
		cancel () {
			this.mPage = {}
			this.$emit('cancel')
		},
		preview () {
			this.doShowViewerDialog = true
		},
		async save () {
			this.displayItemsValidation = true
			if (this.pageSaving) { return }
			if (!this.validatePage()) { return }
			this.setPageSavingStatus(true, false)
			const api = this.mode === EDITOR_MODE.edit ? updateHubPage : createHubPage
			const res = await api(this.mPage)
			if (res.error) {
				if (res.message.includes(this.slugTakenError)) {
					this.existingSlugs = [...this.existingSlugs, this.mPage.slug]
					this.doShowSlugTakenErrorDialog = true
				} else {
					this.showError(`Oops! There was a problem submitting Hub Page.<br>`, res.message)
				}
				console.error(res.error)
				this.setPageSavingStatus(false, true)
			} else {
				this.$emit('saved', res)
				this.mPage = {}
				this.currentCategory = null
				this.currentTopic = null
				this.setPageSavingStatus(false, false)
			}
		},
		setPageSavingStatus(saving, error) {
			this.pageSaving = saving
			this.pageError = error
		},
		onStatusChange(event, item) {
			if (event !== 'Publish') { return }
			if (item.publishDate) { return }
			item.publishDate = moment().format('YYYY-MM-DD')
		},
		resetPageFormValidationDisplay () {
			this.$refs['pageForm']?.resetValidation?.()
		},
		resetItemsValidationDisplay () {
			this.displayItemsValidation = false
			this.warningMessage = ''
			this.timeout && clearTimeout(this.timeout)
		},
		validatePage () {
			if (!this.$refs['pageForm'].validate() || this.hasItemsValidationErrors) {
                this.warningMessage = 'Please correct invalid fields.'
                this.timeout = setTimeout(() => { this.warningMessage = '' }, 3000)
				if (this.hasItemsValidationErrors) {
					this.showInvalidItem()
				}
                return false
            }
			return true
		},
		showInvalidItem () {
			if (this.checkCurrentItemInvalid()) { return }
			const items = Object.entries(this.itemsValidation)
			const topicId = items.find(([_, type]) => type === 'topic')?.[0]
			if (topicId) {
				const category = this.mPage.categories
					.find((category) => category.topics.some((topic) => this.getIdentifier(topic) === topicId))
				const topic = category.topics.find((topic) => this.getIdentifier(topic) === topicId)
				this.currentCategory = category
				this.setCurrentTopic(topic)
			} else {
				const categoryId = items.find(([_, type]) => type === 'category')?.[0]
				const category = this.mPage.categories.find((category) => this.getIdentifier(category) === categoryId)
				this.setCurrentCategory(category)
			}
		},
		checkCurrentItemInvalid () {
			if (this.currentTopic && this.itemsValidation[this.getIdentifier(this.currentTopic)]) {
				this.$refs['itemForm'].validate()
				return true
			}
			if (this.currentCategory && this.itemsValidation[this.getIdentifier(this.currentCategory)]) {
				const invalidTopic = this.currentCategory.topics
					.find((topic) => this.itemsValidation[this.getIdentifier(topic)])
				if (invalidTopic) {
					this.setCurrentTopic(invalidTopic)
				} else {
					if (this.currentTopic) {
						this.setCurrentCategory(this.currentCategory)
					} else {
						this.$refs['itemForm'].validate()
					}
				}
				return true
			}
			return false
		},
		validateCurrentItemForm () {
			this.$nextTick(() => {
				if (this.displayItemsValidation) {
					this.$refs['itemForm'].validate()
				}
			})
		},
		validateCategoryFields (category) {
			if (!category.title) { return false }
			const titleExist = this.mPage.categories
				.some((c) => this.getIdentifier(category) !== this.getIdentifier(c) && category.title === c.title)
			if (titleExist) { return false }
			if (category.status === this.STATUS.publish && !category.publishDate) { return false }
			if (!this.validateDate(category.publishDate)) { return false }
			if (!this.validateDate(category.endDate)) { return false }
			return true
		},
		validateTopicFields (category, topic) {
			if (!topic.title) { return false }
			const titleExist = category.topics
				.some((t) => this.getIdentifier(topic) !== this.getIdentifier(t) && topic.title === t.title)
			if (titleExist) { return false }
			if (!topic.description) { return false }
			if (topic.description?.length > this.topicDescriptionCounter) { return false }
			if (!topic.buttonTitle) { return false }
			if (topic.buttonTitle?.length > this.buttonTitleCounter) { return false }
			if (!topic.buttonLink) { return false }
			if (topic.status === this.STATUS.publish && !topic.publishDate) { return false }
			if (!this.validateDate(topic.publishDate)) { return false }
			if (!this.validateDate(topic.endDate)) { return false }
			return true
		},
		validateDate (date) {
			const validator = dateRule()
			return validator(date) === true ? true : false
		},
		counterRule (counter) {
			return (v) => {
				const length = v?.length || 0
				if (length <= counter) { return true }
				return 'Description length exceeds allowed length.'
			}
		},
		uniqueSlugRule () {
			return (v) => {
				if (!this.existingSlugs.includes(v)) { return true }
				return this.slugTakenError
			}
		},
		categoryNameRule (v) {
			if (!v) { return true }
			const titleExist = this.mPage.categories
				.some((category) => !this.checkCurrentCategory(category) && category.title === v.trim())
			if (!titleExist) { return true }
			return `The Category with title '${v}' already exists in the current Hub Page.`
		},
		topicNameRule (v) {
			if (!v) { return true }
			const titleExist = this.currentCategory.topics
				.some((topic) => !this.checkCurrentTopic(topic) && topic.title === v.trim())
			if (!titleExist) { return true }
			return `The Topic with title '${v}' already exists in the selected Category.`
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-page-editor {
	position: relative;

	.page-details {
		&.page-details-row {
			max-width: 100%;
			display: flex;
			flex-wrap: wrap;
			gap: 1rem 3rem;
		}

		.page-details-col {
			max-width: 100%;
		}

		.col-title {
			flex: 1 1 300px;
		}
		
		.col-description {
			flex: 1 1 360px;
		}
	}

	.page-items {
		&.page-items-row {
			margin-top: 2rem;
			width: 100%;
			min-height: 41.5rem;
			display: flex;
			flex-wrap: wrap;
			gap: 1px 1px;
			padding: 1px;
		}

		.page-items-col {
			max-width: 100%;
			min-height: 10rem;
			outline: $outline;
		}

		.col-categories,
		.col-topics {
			flex: 1 5 320px;
		}

		.col-editor {
			flex: 5 1 460px;
		}
	}

	.field-bordered-wrapper {
		width: 100%;
		padding: 0.25rem 0.5rem;
		border: thin solid $border-color;
		border-radius: 2px;

		.description-field.v-input {
			::v-deep .v-input__slot {
				&::before {
					border-color: transparent !important;
				}
			}
		}
	}

	.hub-page-title {
		min-height: 3rem;
		display: flex;
		align-items: center;
		padding: 0.25rem 1rem;
		font-size: 1.125rem;
		font-weight: 800;
		outline: $outline;
		margin-bottom: 1px;
	}

	.hub-page-tip {
		min-height: 3rem;
		display: flex;
		align-items: center;
		padding: 0.25rem 1rem;
		opacity: 0.6;
	}

	.list-item.v-list-item {
		min-height: 48px;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		align-items: center;
		outline: $outline;
		margin: 1px 0;

		.v-list-item__icon {
			height: 36px;
			margin: 0 1rem 0 0;
			align-self: unset;
		}

		.list-item__title {
			font-weight: 500;
		}

		.list-item__action {
			margin: 0 0 0 1rem;
			flex-direction: row;
			flex-wrap: nowrap;
			flex-shrink: 0;
			align-self: unset;
		}

		&.list-item--active {
			background-color: $color-primary-tone;

			.list-item__title {
				color: $color-primary;
			}
		}

		.validation-mark.v-icon {
			position: absolute;
			top: 0.125rem;
			left: 0.125rem;
			font-size: 0.75rem !important;
			color: red;
		}
	}

	.editor-title {
		outline: none;
	}

	.editor-wrapper {
		padding: 0 1rem 1rem;
	}

	.fields-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem 2rem;
	}

	.fields-col {
		max-width: 100%;
		flex: 1 1 240px;
	}

	.status-fields-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 0 2rem;
	}

	.status-col {
		max-width: 100%;
		flex: 1 2 100px;
	}

	.dates-row {
		max-width: 100%;
		flex: 2 1 352px;
		display: flex;
		flex-wrap: wrap;
		gap: 0 2rem;
	}

	.dates-col {
		max-width: 100%;
		flex: 1 1 160px;
	}

	.preview-btn {
		margin-right: 1rem;
		text-decoration: underline;
	}

	.handle-item {
		cursor: move;
	}

	&__warning-message {
		margin-bottom: 0;
		max-width: 100%;
		position: absolute;
		bottom: 0;
		z-index: 1;
	}
}
</style>
